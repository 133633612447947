<template>
  <div>
    <l-toggle :FormButton="FormButton">
      <el-form
        ref="elfrom"
        :model="searchForm"
        size="small"
        label-width="90px"
      >
        <el-row>
          <el-col>
            <l-text-filter
              @refreshTable="refreshTable"
              :filterList="filterList"
              :width="'80px'"
            >
            </l-text-filter>
          </el-col>
        </el-row>
        <el-row type="flex" justify="start">
          <el-col :xs="24" :sm="12" :lg="6">
            <el-form-item label="机型">
              <el-input
                v-model.trim="searchForm.modelName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </l-toggle>
    <div class="tableBox">
      <el-table
        ref="eltable"
        :data="tableData"
        :header-cell-style="{background:'#f2f2f2'}"
        height="100%"
        stripe
        border
        v-loading="tableLoading"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <img src="@/assets/img/table_noData.png" width="300" height="300" />
        </template>
        <el-table-column type="selection" width="50" align="center"></el-table-column>

        <el-table-column prop="modelCode" label="产品ID" align="center" width="100"></el-table-column>
        <el-table-column prop="firstCateName" label="产品大类" align="center"></el-table-column>
        <el-table-column prop="brandCateName" label="产品品牌" align="center"></el-table-column>
        <el-table-column prop="modelName" label="产品机型" align="center"></el-table-column>
        <el-table-column prop="createTime" label="添加时间" align="center" width="150"></el-table-column>
        <el-table-column prop="udpateTime" label="最后更新时间" align="center" width="150"></el-table-column>
        <el-table-column prop="status" label="当前状态" align="center" width="80">
          <template slot-scope="scope">
            {{ valueToName(filterDictionary('0112'), scope.row.status) }}
          </template>
        </el-table-column>

        <el-table-column prop="basicPrice" label="基础价格" align="center" width="100"></el-table-column>
        <el-table-column prop="imageUrl" label="图片" align="center" width="80">
          <template slot-scope="scope">
            <el-link type="success" @click="watchImg(scope.row.imageUrl)">查看</el-link>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row)"
            >历史估值维护
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px;"
        background
        :disabled="tableLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import LToggle from '@/components/LToggle.vue';
import LTextFilter from '@/components/LTextFilter.vue';
import api from '@/api/ProHistoryApi';
import appApi from '@/api/app';

export default {
  name: 'ProHistory',
  components: {
    LToggle,
    LTextFilter,
  },
  data() {
    return {
      searchForm: {
        modelName: '',
        brandCateId: '',
        firstCateId: '',
      },
      FormButton: [
        {
          icon: 'el-icon-search',
          name: '查询',
          type: 'primary',
          loading: false,
          click: this.searchEvent,
        },
      ],
      tableLoading: false,
      tableData: [],
      selectedTableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 30,
        total: 300,
      },
      firstPro: [],
      secondPro: [],
    };
  },
  watch: {
    tableLoading(nval) {
      this.FormButton[0].loading = nval;
    },
  },
  computed: {
    filterList() {
      const firstPro = this.firstPro.map(val => {
        const obj = {
          name: val.cateName,
          value: val.cateCode,
          id: val.id,
          cateLevel: val.cateLevel,
        };
        return obj;
      });
      const arr = [
        {
          name: '产品大类：',
          data: firstPro,
        },
      ];

      const secondPro = this.secondPro.map(val => {
        const obj = {
          name: val.cateName,
          value: val.cateCode,
          id: val.id,
          cateLevel: val.cateLevel,
        };
        return obj;
      });
      const bigObj = {
        name: '品牌：',
        data: secondPro,
      };
      if (secondPro.length) {
        arr.push(bigObj);
      }
      return arr;
    },
  },
  created() {
    this.reqTableData();
    this.getFirstPro();
  },
  methods: {
    // 获取品牌产品
    getSecondPro(cateLevel, parentCateId) {
      appApi.brand(
        {
          cateLevel,
          parentCateId,
        },
      )
        .then(res => {
          this.secondPro = res.result;
        }).catch(err => {
          console.log(err);
        });
    },
    // 获取大类产品
    getFirstPro() {
      appApi.first()
        .then(res => {
          this.firstPro = res.result;
        }).catch(err => {
          console.log(err);
        });
    },
    // 维护估值历史
    edit(row) {
      const routeUrl = this.$router.resolve({
        path: `/ProHistoryEdit/${row.id}`,
      });
      window.open(routeUrl.href, '_blank');
    },
    searchEvent() {
      this.reqTableData();
    },
    refreshTable(data) {
      // 如果有data，说明是从筛选组件中过来
      if (data) {
        const { tags, self, type } = data;
        // 点击的是筛选文字
        if (type === 1) {
          if (`${self.cateLevel}` === '1') {
            this.getSecondPro(2, self.id);
            this.searchForm.firstCateId = self.id;
          } else if (`${self.cateLevel}` === '2') {
            this.searchForm.brandCateId = self.id;
          }
        // 点击的是关闭标签
        } else if (type === 0) {
          if (tags.length) {
            if (`${tags[0].cateLevel}` === '1') {
              this.getSecondPro(2, tags[0].id);
              this.searchForm.firstCateId = tags[0].id;
              this.searchForm.brandCateId = '';
              this.secondPro = [];
            } else if (`${tags[0].cateLevel}` === '2') {
              this.searchForm.brandCateId = tags[0].id;
              this.searchForm.firstCateId = '';
            }
          } else {
            this.searchForm.firstCateId = '';
            this.searchForm.brandCateId = '';
            this.secondPro = [];
          }
        }
      }
      this.pageInfo.pageNum = 1;
      this.reqTableData();
    },
    reqTableData() {
      this.tableLoading = true;
      const {
        modelName,
        firstCateId,
        brandCateId,
      } = this.searchForm;

      const {
        pageNum,
        pageSize,
      } = this.pageInfo;
      api.modellist(
        {
          modelName,
          firstCateId,
          brandCateId,
          pageNum,
          pageSize,
        },
      )
        .then(res => {
          this.tableData = res.result.datas;
          this.pageInfo.total = res.result.totalRecords;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSelectionChange(select) {
      this.selectedTableData = select;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.reqTableData();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.reqTableData();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
