import { postInfo } from './api';

export default {
  // 获取机型分页列表
  modellist(data) {
    return postInfo('/product/model/list', data);
  },
  // 机型估值历史
  modelassessmentlist(data) {
    return postInfo('/product/model/assessment/list', data);
  },
  // 删除机型估值历史
  modelassessmentdelete(data) {
    return postInfo('/product/model/assessment/delete', data);
  },
  // 添加机型估值历史
  modelassessmentadd(data) {
    return postInfo('/product/model/assessment/add', data);
  },
};
